import ConfettiGenerator from "confetti-js";

document.addEventListener("DOMContentLoaded", function () {
  setTimeout(() => {
    const elem = document.querySelector("#confetti-canvas");
    if (elem) {
      const confettiSettings = {
        target: "confetti-canvas",
        start_from_edge: true,
        rotate: true,
      };
      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    }
  }, 500);
});
