import crel from "crel";
import _ from "lodash-es";

document.addEventListener("DOMContentLoaded", function () {
  const body = document.querySelector("body");
  var professors = document.querySelectorAll(".professor");
  _.each(professors, function (professor) {
    var professorPopup = crel("div", { class: "professor-popup" });
    var professorPopupOverlay = crel("div", { class: "popup-overlay" });
    var professorPopupContent = crel("div", {
      class: "professor-popup-content",
    });

    professorPopupContent.innerHTML = professor.innerHTML;
    professorPopup.appendChild(professorPopupContent);

    var professorBio = professor.querySelector(".professor-bio");
    if (professorBio) {
      professor.removeChild(professorBio);
    }

    var x = crel(
      "i",
      { class: "professor-popup-close-button material-icons md-36" },
      "close"
    );

    const closePopup = function (event) {
      document.body.style.overflow = "auto";
      professorPopupOverlay.classList.remove("open");
      event.stopPropagation();
      setTimeout(() => professorPopupOverlay.classList.remove("visible"), 200);
    };

    x.onclick = closePopup;
    professorPopupOverlay.onclick = closePopup;

    professorPopup.appendChild(x);
    professorPopupOverlay.appendChild(professorPopup);
    body.appendChild(professorPopupOverlay);

    professor.onclick = function () {
      professorPopupOverlay.classList.add("open");
      professorPopupOverlay.classList.add("visible");
      document.body.style.overflow = "hidden";
    };

    professorPopup.onclick = (e) => e.stopPropagation();
  });
});
