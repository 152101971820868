import countdown from "countdown";
import prices from "../../shared/prices.json";

const limitDate = new Date(prices.deadline);

document.addEventListener("DOMContentLoaded", () => {
  const pageTimers = document.querySelectorAll(".inscription-countdown");

  const deleteCountdownWrappers = () => {
    const countdownWrappers = document.querySelectorAll(
      ".inscription-countdown-wrapper"
    );

    countdownWrappers.forEach((wrapper) => {
      wrapper.parentElement.removeChild(wrapper);
    });
  };

  if (limitDate < new Date()) {
    deleteCountdownWrappers();
  }

  countdown.setLabels(
    " milliseconde| seconde| minute| heure| jour| semaine| mois| année| décennie| siècle| millénaire",
    " millisecondes| secondes| minutes| heures| jours| semaines| mois| années| décennies| siècles| millénaires",
    " et ",
    ", ",
    "maintenant"
  );

  countdown(
    limitDate,
    (timespan) => {
      if (timespan.value > 0) {
        deleteCountdownWrappers();
      }
      pageTimers.forEach((timer) => {
        timer.innerHTML = timespan.toHTML("strong");
      });
    },
    countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS,
    1
  );
});
