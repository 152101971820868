document.addEventListener("DOMContentLoaded", function (event) {
  const offscreenNav = document.querySelector(".main-nav-offscreen");
  const openButton = document.querySelector(".main-nav-offscreen-opener");
  const closeButton = document.querySelector(
    ".main-nav-offscreen-close-button"
  );
  closeButton.onclick = function () {
    offscreenNav.classList.remove("open");
    setTimeout(() => offscreenNav.classList.add("invisible"), 200);
  };
  openButton.onclick = function () {
    offscreenNav.classList.remove("invisible");
    offscreenNav.classList.add("open");
  };
});
