import Masonry from "masonry-layout";
import LazyLoad from "vanilla-lazyload";
import crel from "crel";

document.addEventListener("DOMContentLoaded", function () {
  var masonryGridElement = document.querySelector("#masonry-grid");
  if (masonryGridElement) {
    var masonryGrid = new Masonry(masonryGridElement, {
      columnWidth: ".masonry-grid-sizer",
      gutter: 0,
      percentPosition: true,
      itemSelector: ".masonry-grid-item",
      isAnimated: false,
    });
    var myLazyLoad = new LazyLoad({
      callback_load: addPopupHandler,
      callback_set: recalculateLayout,
      callback_processed: recalculateLayout,
    });

    function recalculateLayout() {
      masonryGrid.layout();
    }
    function addPopupHandler(imageElem) {
      recalculateLayout();
      if (imageElem.getAttribute("data-full-size")) {
        imageElem.onclick = function () {
          var overlay = crel("div", { class: "popup-overlay visible" });

          var x = crel(
            "i",
            { class: "popup-overlay-x material-icons md-36" },
            "close"
          );
          const closePopup = function (event) {
            document.body.removeChild(overlay);
            document.body.style.overflow = "auto";
          };
          x.onclick = closePopup;
          overlay.onclick = closePopup;

          overlay.appendChild(x);

          var popupImage = crel("img", {
            class: "popup-image",
            src: imageElem.getAttribute("data-full-size"),
          });
          overlay.appendChild(popupImage);

          console.log(overlay);
          document.body.style.overflow = "hidden";
          document.body.appendChild(overlay);
          setTimeout(() => overlay.classList.add("opacity-100"));
        };
      }
    }
  }
});
